import { Box, Button, Grid, TextField, useMediaQuery } from "@mui/material";
import Logo from "../../images/logo/logoOne.png";
import { useState } from "react";

const EmailChallengerTwo = ({ props }) => {
  const [firstNameInput, setFirstNameInput] = useState(props.firstName);
  const [lastNameInput, setLastNameInput] = useState(props.lastName);

  const queryWidth = "800px";

  let textFieldStyle;
  if (useMediaQuery(`(max-width: ${queryWidth})`)) {
    // mobile
    textFieldStyle = "small";
  } else {
    // desktop
    textFieldStyle = "medium";
  }

  return (
    <>
      <Box
        className="items"
        sx={{
          boxShadow: 8,
          borderRadius: 6,
          alignSelf: "center",
          width: "90%",
        }}
      >
        <div
          className="items"
          style={{
            marginTop: "50px",
          }}
        >
          <img src={Logo} alt="LOGO" />

          <h1 className="steps">
            {props.challengerButtonsScript.step} 1{" "}
            {props.challengerButtonsScript.of} 3
          </h1>
          <h2 className="desc">{props.challengeTwoScript.nameHeader}</h2>

          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item className="grid">
              <h2 className="names">{props.challengeTwoScript.firstName}</h2>
              <TextField
                fullWidth
                id="firstName"
                variant="outlined"
                defaultValue={props.firstName}
                onChange={(e) => setFirstNameInput(e.target.value)}
                size={textFieldStyle}
              />
            </Grid>
            <Grid item className="grid">
              <h2 className="names">{props.challengeTwoScript.lastName}</h2>
              <TextField
                fullWidth
                id="lastName"
                variant="outlined"
                defaultValue={props.lastName}
                onChange={(e) => setLastNameInput(e.target.value)}
                size={textFieldStyle}
              />
            </Grid>

            <Grid item className="grid">
              <Button
                disableElevation
                variant="contained"
                size="large"
                fullWidth
                disabled={firstNameInput === "" || lastNameInput === ""}
                sx={{
                  bgcolor: "#002129",
                  "&:hover": { bgcolor: "#000" },
                  margin: "35px 0px 0px 0px",
                  fontFamily: "LugfaRegular",
                  textTransform: "none",
                }}
                onClick={() => {
                  props.setFirstName(firstNameInput);
                  props.setLastName(lastNameInput);
                  props.setCurrentPage(4);
                }}
              >
                {props.challengerButtonsScript.confirm}
              </Button>
              <Button
                disableElevation
                variant="text"
                size="large"
                fullWidth
                sx={{
                  margin: "25px 0px 0px 0px",
                  color: "#002129",
                  fontFamily: "LugfaRegular",
                  textTransform: "none",
                }}
                style={{ backgroundColor: "transparent" }}
                onClick={() => props.setCurrentPage(2)}
              >
                <u
                  style={{
                    marginBottom: "35px",
                  }}
                >
                  {props.challengerButtonsScript.previousStep}
                </u>
              </Button>
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default EmailChallengerTwo;
