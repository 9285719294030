import { Box, Button, Grid } from "@mui/material";
import Logo from "../../images/logo/logoOne.png";

const Confirmed = ({ senderFirstName, challengeCompleteScript }) => {
  return (
    <>
      <Box
        className="items"
        sx={{
          boxShadow: 8,
          borderRadius: 6,
          alignSelf: "center",
          width: "80%",
        }}
      >
        <div
          className="items"
          style={{
            margin: "50px 0px 50px 0px",
          }}
        >
          <img src={Logo} alt="LOGO" />

          <h2 className="steps">{challengeCompleteScript.summaryLabel}</h2>
          <h3 className="desc">
            {challengeCompleteScript.summarySenderMessage}
          </h3>

          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item className="grid">
              <Button
                disableElevation
                variant="text"
                size="large"
                fullWidth
                style={{ backgroundColor: "transparent" }}
                sx={{
                  margin: "25px 0px 0px 0px",
                  color: "#002129",
                  fontFamily: "LugfaRegular",
                  textTransform: "none",
                }}
              >
                <u>
                  <a
                    href={"mailto:support@scamnetic.com"}
                    target={"_blank"}
                    style={{ color: "#000" }}
                  >
                    {challengeCompleteScript.summaryContactUs}
                  </a>
                </u>
              </Button>
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default Confirmed;
