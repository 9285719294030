import { Box } from "@mui/material";
import "./Challenger.css";

const ErrorPage = ({ message }) => {
  return (
    <>
      <Box
        className="desc"
        style={{ background: "#FFF" }}
        sx={{
          boxShadow: 3,
          borderRadius: 6,
          alignSelf: "center",
          width: "90%",
        }}
      >
        <h3>{message}</h3>
      </Box>
    </>
  );
};

export default ErrorPage;
