import { Box, Button, Grid, MenuItem, Select } from "@mui/material";
import Logo from "../images/logo/logoOne.png";

const LanguageButton = ({
  setLanguage,
  language,
  updateLanguageSettings,
  challengerButtonsScript,
  setCurrentPage,
}) => {
  return (
    <>
      <Box
        className="items"
        sx={{
          boxShadow: 8,
          borderRadius: 6,
          alignSelf: "center",
          width: "80%",
        }}
      >
        <div
          className="items"
          style={{
            margin: "50px 0px 50px 0px",
          }}
        >
          <img src={Logo} alt="LOGO" />
          {/* 
          <h1 className="steps">
            {challengerButtonsScript.step} 1 {challengerButtonsScript.of} 4
          </h1> */}
          <h2 className="desc">Please select your prefered language.</h2>

          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              className="grid"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                sx={{
                  width: "50%",
                }}
              >
                <MenuItem value={"en_US"}>English (US)</MenuItem>
                <MenuItem value={"fr_CA"}>Français</MenuItem>
              </Select>
            </Grid>

            <Grid item className="grid">
              <Button
                disableElevation
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  bgcolor: "#002129",
                  "&:hover": { bgcolor: "#000" },
                  margin: "35px 0px 0px 0px",
                  fontFamily: "LugfaRegular",
                  textTransform: "none",
                }}
                onClick={() => {
                  updateLanguageSettings(language);
                }}
              >
                OK{challengerButtonsScript.confirm}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default LanguageButton;
