import { Box, Button, Grid } from "@mui/material";
import Logo from "../../images/logo/logoOne.png";

const EmailChallengerFour = ({ props }) => {
  return (
    <>
      <Box
        className="items"
        sx={{
          boxShadow: 8,
          borderRadius: 6,
          alignSelf: "center",
          width: "80%",
        }}
      >
        <div
          className="items"
          style={{
            margin: "50px 0px 50px 0px",
          }}
        >
          <img src={Logo} alt="LOGO" />

          <h1 className="steps">
            {props.challengerButtonsScript.step} 3{" "}
            {props.challengerButtonsScript.of} 3
          </h1>
          <h3 className={"desc"}>
            {props.challengeFourScript.permissionHeader}
            <br />
            <br />
            {props.challengeFourScript.permissionHeaderRequest}
          </h3>

          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item className="grid">
              <Button
                disableElevation
                variant="contained"
                size="large"
                fullWidth
                sx={{
                  bgcolor: "#002129",
                  "&:hover": { bgcolor: "#000" },
                  margin: "25px 0px 0px 0px",
                  fontFamily: "LugfaRegular",
                  textTransform: "none",
                }}
                onClick={() => {
                  props.submitData();
                }}
              >
                {props.challengeFourScript.permissionYes}
              </Button>
              <Button
                disableElevation
                variant="text"
                size="large"
                fullWidth
                sx={{
                  margin: "25px 0px 0px 0px",
                  color: "#002129",
                  fontFamily: "LugfaRegular",
                  textTransform: "none",
                }}
                style={{ backgroundColor: "transparent" }}
                onClick={() => props.setCurrentPage(2)}
              >
                <u>{props.challengeFourScript.permissionNo}</u>
              </Button>
              <Button
                disableElevation
                variant="text"
                size="large"
                fullWidth
                sx={{
                  margin: "25px 0px 0px 0px",
                  color: "#002129",
                  fontFamily: "LugfaRegular",
                  textTransform: "none",
                }}
                style={{ backgroundColor: "transparent" }}
                onClick={() => props.setCurrentPage(4)}
              >
                <u>{props.challengerButtonsScript.previousStep}</u>
              </Button>
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default EmailChallengerFour;
